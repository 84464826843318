<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div>
    <div class="file-box">
      <v-container fluid>
        <v-row>
          <v-col
            v-for="(fileItem, index) in computedFiles"
            :key="index"
            :cols="computedCol.cols"
            :lg="computedCol.lg"
            :md="computedCol.md"
            :sm="computedCol.sm"
          >
            <TypeC-btn :fileItem="fileItem">{{
              fileItem.original_filename
            }}</TypeC-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-if="showMoreBtn" class="d-flex justify-center mt-6">
      <Main-btn @click="showAll">{{ $t("action.look-more") }}</Main-btn>
    </div>
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default {
  props: {
    grid: {
      type: Object,
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    defaultCol: {
      cols: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
    showAll: false,
  }),
  computed: {
    computedCol() {
      return {
        ...this.defaultCol,
        ...this.grid,
      };
    },
    showMoreBtn() {
      return this.files.length > 10;
    },
    computedFiles() {
      if (!this.showAll) return this.files.slice(0, 10);
      return this.files;
    },
  },
};
</script>
